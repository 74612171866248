@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;

.device {
  @include flex-column;
  height: $size-full;

  &__info,
  &__table {
    margin-top: $size-16;
  }

  &__table {
    @include flex-1;

    .icon {
      cursor: pointer;
    }
  }
}
