@use '../../../../themes/mixins' as *;
@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/mixins' as *;

.device-filter {
  flex: 1.5;
  background-color: $color-white;
  padding: $size-16;
  border-radius: $size-6;
}
