@use './font-weights' as *;
@use './sizes' as *;

/* FLEX */
@mixin flex-1 {
  flex: 1;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-vert-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/* FONT SIZE */
@mixin font-size($font-size: $size-14, $font-weight: $font-weight-400, $line-height: $size-20) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

/* RESPONSIVE */
$XS: 480px;
$SM: 768px;
$MD: 1024px;
$XL: 1170px;

@mixin Responsive($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) {
      @content;
    }
  } @else if $canvas == SM {
    @media only screen and (min-width: $SM) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}
