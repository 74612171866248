@use '../../../themes/sizes' as *;
@use '../../../themes/mixins' as *;
@use '../../../themes/colors' as *;
@use '../../../themes/font-weights' as *;

.select {
  &__label {
    margin-bottom: $size-4;

    &-text,
    &-required {
      display: inline-block;
    }

    &-required {
      margin-left: $size-4;
      color: $color-light-red;
      font-size: $size-12;
    }
  }

  &__main {
    width: $size-full;
  }

  &--horizontal {
    @include flex-center-vert;

    & > .select__label {
      margin-bottom: $size-0;
      margin-right: $size-8;
    }

    & > .select__main {
      @include flex-1;
    }
  }
}
