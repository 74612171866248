@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;

.add-media-content {
  .field {
    & + .field {
      margin-top: $size-20;
    }
  }

  .footer {
    margin-top: $size-20;
    margin-left: -$size-20;
    margin-right: -$size-20;
    margin-bottom: -$size-4;
    padding-top: $size-12;
    padding-right: $size-20;
    border-top: $size-1 solid $color-soft-peach;

    .button {
      & + .button {
        margin-left: $size-8;
      }
    }
  }
}
