@use '../../themes/colors' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;
@use '../../themes/font-weights' as *;

.profile__tooltip {
  @include flex-center-vert;
  cursor: pointer;

  &-icon {
    margin-bottom: $size-2;
  }

  &-text {
    margin-left: $size-8;
  }
}

.header {
  display: flex;
  padding: 0 $size-32;

  .user {
    @include flex-center-vert;
    margin-left: auto;

    &__image {
      border-radius: 50%;
      overflow: hidden;
      margin-right: $size-8;
    }

    &__text {
      &-name {
        @include flex-center-vert;
        cursor: pointer;
        font-size: $size-16;
        line-height: $size-24;
        font-weight: $font-weight-500;
      }

      &-position {
        font-weight: $size-12;
        line-height: $size-16;
        color: $color-smokey-grey;
      }
    }
  }
}
