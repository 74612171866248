@use '../../../themes/sizes' as *;
@use '../../../themes/colors' as *;
@use '../../../themes/mixins' as *;
@use '../../../themes/font-weights' as *;

.upload-file {
  width: fit-content;

  .file-label {
    margin-bottom: $size-4;

    &__text,
    &__required {
      display: inline-block;
    }

    &__required {
      margin-left: $size-4;
      color: $color-light-red;
      font-size: $size-12;
    }
  }

  .file-main {
    &__input {
      width: $size-1;
      height: $size-1;
    }

    &__label {
      @include flex-center;
      min-width: $size-200;
      min-height: $size-100;
      background-color: $color-alice-blue;
      border: $size-1 dashed $color-crystal-blue;
      border-radius: $size-8;
      cursor: pointer;
      position: relative;
    }

    &__icon {
      position: absolute;
      top: -$size-8;
      right: -$size-8;
      font-size: $size-16;
      cursor: pointer;
      color: $color-light-red;
      background-color: $color-white;
      border-radius: $size-16;
    }
  }

  &__error {
    font-size: $size-12;
    color: $color-light-red;
    margin-top: $size-2;
  }

  &--disabled {
    pointer-events: none;

    .file-main {
      &__label {
        border-color: $color-light-grey;
        background-color: $color-light-grey-2;
      }

      &__text {
        color: $color-grey;
      }
    }
  }

  &--has-file {
    .file-main__label {
      cursor: default;
    }
  }
}
