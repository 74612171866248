@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;

.media-content {
  @include flex-column;
  height: $size-full;

  &__table {
    @include flex-1;
    margin-top: $size-16;

    .icons {
      .icon {
        cursor: pointer;

        &--hide {
          display: none;
        }
      }
    }
  }
}
