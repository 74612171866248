@use '../../../themes/colors' as *;
@use '../../../themes/sizes' as *;
@use '../../../themes/mixins' as *;

.tree-table {
  &__loading {
    font-size: $size-34 !important;
  }

  &__empty {
    &--hidden {
      visibility: hidden;
    }
  }

  .ant-table {
    border-bottom-left-radius: $size-8;
    border-bottom-right-radius: $size-8;
    overflow: hidden;

    .expand-icon {
      cursor: pointer;
      display: inline-block;
      width: $size-16;
      height: $size-16;
      margin-right: $size-8;
      margin-bottom: -$size-2p;

      &--hide {
        cursor: default;
      }
    }

    .ant-table-cell-with-append {
      // display: flex;
    }

    th.ant-table-cell,
    th.ant-table-cell + .ant-table-cell {
      border-bottom: $size-2 solid $color-cultured !important;
    }

    &-footer {
      padding: 0;
    }
  }
}
