@use '../../themes/colors' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;
@use '../../themes/font-weights' as *;

.sider {
  .ant-layout-sider-children {
    height: $size-full-vh;
  }

  &__content {
    height: $size-full;

    .back {
      padding: $size-8 $size-24;
      background-color: $color-flush-mahogany;

      &__icon {
        color: $color-white;
        cursor: pointer;
        margin-right: $size-16;

        &--collapsed {
          width: 100%;
          margin-right: $size-0;
        }
      }

      &__title {
        color: $color-white;
      }
    }

    .header {
      padding: $size-32 $size-24;

      &__menu-icon {
        cursor: pointer;
      }

      &__media-icon {
        margin-left: $size-16;
      }

      &__title {
        color: $color-white;
        margin-left: $size-12;
        font-size: $size-16;
        font-weight: $font-weight-500;
      }

      &--collapsed {
        @include flex-center;
      }
    }

    .dot-icon {
      min-width: auto !important;
      position: relative;
      height: 100%;

      &::after {
        content: '';
        height: 50%;
        width: $size-1;
        background-color: $color-white;
        opacity: 0.2;
        position: absolute;
        left: $size-2;
      }

      &--top::after {
        top: 50%;
      }

      &--middle::after {
        height: 100%;
      }

      &--bottom::after {
        bottom: 50%;
      }
    }

    .logout {
      margin-top: auto;
      padding: 0 0 $size-34 $size-24;

      &__icon {
        cursor: pointer;
      }

      &__text {
        color: $color-white;
        font-size: $size-16;
        margin-left: $size-8;
      }

      &--collapsed {
        @include flex-center;
        padding-left: $size-0;
      }
    }
  }
}
