@use './themes/font-weights' as *;
@use './themes/sizes' as *;
@use './themes/mixins' as *;
@use './themes/colors' as *;

html,
body,
#root,
#root > * {
  height: 100%;
}

body {
  margin: 0;
  box-sizing: border-box;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}

/* ANT DESIGN CUSTOM */

// CONFIRM MODAL
.ant-modal-content {
  padding: 0 !important;

  .ant-modal-confirm-body-wrapper {
    .anticon-exclamation-circle {
      display: none;
    }

    .ant-modal-confirm-paragraph {
      row-gap: 0;
      max-width: $size-full;

      .ant-modal-confirm-title {
        @include font-size($size-16, $font-weight-500, $size-24);
        font-family: 'Be Vietnam Pro';
        text-align: center;
        padding: $size-16 0;
        border-bottom: $size-1 solid $color-soft-peach;
      }

      .ant-modal-confirm-content {
        @include font-size($size-16, $font-weight-400, $size-24);
        font-family: 'Be Vietnam Pro';
        padding: $size-16 $size-20;

        .ant-table {
          .ant-table-footer {
            padding: 0;
          }
        }

        .ant-pagination {
          &-item-active {
            background-color: $color-rose-madder;

            & > a {
              color: $color-white;
            }

            &:hover {
              border-color: $color-white;
            }
          }
        }

        .ant-btn {
          border-radius: $size-4;
        }
      }
    }

    .ant-modal-confirm-btns {
      margin-top: 0;
      padding: $size-12 $size-20;
      border-top: $size-1 solid $color-soft-peach;

      .ant-btn:not(
          .ant-btn-dangerous,
          .ant-btn-primary,
          .ant-btn-dashed,
          .ant-btn-link,
          .ant-btn-text
        ):hover {
        color: #2560e5;
        border-color: #2560e5;
      }

      .ant-btn.ant-btn-primary {
        background-color: #2560e5;

        &:hover {
          opacity: 0.8;
        }
      }

      .ant-btn > span {
        @include font-size($size-16, $font-weight-400, $size-20);
        font-family: 'Be Vietnam Pro';
      }

      .ant-btn:focus-visible {
        outline: none !important;
      }
    }
  }
}

// SUBMENU POPUP
.ant-menu.ant-menu-sub.ant-menu-vertical {
  background-color: #c92127;
}
