@use '../../../../themes/colors' as *;
@use '../../../../themes/sizes' as *;
@use '../../../../themes/mixins' as *;

.device-online-statistic {
  flex: 1;
  padding: $size-16;
  border-radius: $size-6;
  background-color: $color-white;

  .online-number {
    color: $color-green-teal;
  }

  .offline-number {
    color: $color-light-red;
  }
}
