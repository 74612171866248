@use '../../../themes/sizes' as *;

.table {
  &__loading {
    font-size: $size-34 !important;
  }

  &__empty {
    &--hidden {
      visibility: hidden;
    }
  }

  .ant-table {
    border-bottom-left-radius: $size-8;
    border-bottom-right-radius: $size-8;
    overflow: hidden;

    &-footer {
      padding: 0;
    }
  }
}
