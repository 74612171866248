@use '../../../themes/mixins' as *;
@use '../../../themes/sizes' as *;
@use '../../../themes/colors' as *;
@use '../../../themes/font-weights' as *;

.page-header {
  @include flex-center-vert;
  padding: 0;

  &__back {
    padding: $size-10 $size-12;
    border-radius: $size-6;
    background-color: $color-white;
    margin-right: $size-24;
    cursor: pointer;
  }

  &__title {
    @include font-size($size-22, $font-weight-700, $size-28);
  }

  &__actions {
    margin-left: auto;
  }
}
