@use '../../../../themes/mixins' as *;
@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;

.media-content-filter {
  margin-top: $size-16;
  background-color: $color-white;
  padding: $size-16;
  border-radius: $size-6;

  .field {
    & > .date-picker__main,
    & > .input__main {
      width: $size-250;
    }
  }
}
